import { Box, Container } from "@mui/material"
import React from "react"

const SectionWrapper = ({ maxWidth, light, children }) => {
  return (
    <Box bgcolor={light ? "#fff" : null}>
      <Container maxWidth={maxWidth ? maxWidth : undefined}>
        <Box py={10}>{children}</Box>
      </Container>
    </Box>
  )
}

export default SectionWrapper
