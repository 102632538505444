import * as React from "react"
// import { Link } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import { Box } from "@mui/material"

const Layout = ({ children, originalPath }) => {
  // const rootPath = `${__PATH_PREFIX__}/`
  // const isRootPath = location.pathname === rootPath
  // let header

  // if (isRootPath) {
  //   header = (
  //     <h1 className="main-heading">
  //       <Link to="/">{title}</Link>
  //     </h1>
  //   )
  // } else {
  //   header = (
  //     <Link className="header-link-home" to="/">
  //       {title}
  //     </Link>
  //   )
  // }

  return (
    <div>
      <header>
        <Header originalPath={originalPath} />
      </header>
      <main>
        <Box>
          {children}
        </Box>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  )
}

export default Layout
