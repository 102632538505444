import { Box, Typography } from "@mui/material"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Footer = () => {
  return (
    <Box
      px={2}
      py={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box style={{ fontSize: 0 }}>
        <StaticImage
          src="../images/Generation-Equality-logo-en.png"
          alt="GEF logo"
          placeholder="tracedSVG"
          height={56}
        />
      </Box>
      <Box>
        <Typography gutterBottom variant="overline" color="textSecondary">
          With the support of:
        </Typography>
        <Box style={{ fontSize: 0 }}>
          <StaticImage
            src="../images/Finland.png"
            alt="Finland logo"
            placeholder="tracedSVG"
            height={56}
          />
        </Box>
      </Box>
      {/* © {new Date().getFullYear()}, Built with
      {` `}
      <a href="https://www.gatsbyjs.com">Gatsby</a> */}
    </Box>
  )
}

export default Footer