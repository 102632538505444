import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SectionWrapper from "../components/sectionWrapper"
import { Box, Tooltip, Typography } from "@mui/material"
import { Button } from "gatsby-material-ui-components"
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const BlogPostTemplate = ({ data, location }) => {
  const post = data.wpPost
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.title}
        description={post.description || post.excerpt}
      />
      <SectionWrapper light>
        <article
          className="blog-post"
          itemScope
          itemType="http://schema.org/Article"
        >
          <Box component="nav" pb={5}>
            <Button to="/news" variant="outlined" startIcon={<ArrowBackIcon />}>
              All posts
            </Button>
          </Box>
          <header>
            <Typography variant="overline">{post.date}</Typography>
            <Typography itemProp="headline" variant="h2" component="h1">
              {post.title}
            </Typography>
          </header>
          <section
            dangerouslySetInnerHTML={{
              __html: post.content,
            }}
            itemProp="articleBody"
          />
        </article>
        <Box
          component="nav"
          display="flex"
          justifyContent={previous ? "space-between" : "flex-end"}
          pt={5}
        >
          {previous && (
            <Tooltip title="Previous post">
              <Button to={`/news/${previous.slug}`} size="large" rel="prev">
                ← {previous.title}
              </Button>
            </Tooltip>
          )}
          {next && (
            <Tooltip title="Next post">
              <Button to={`/news/${next.slug}`} size="large" rel="next">
                {next.title} →
              </Button>
            </Tooltip>
          )}
        </Box>
      </SectionWrapper>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    wpPost(slug: { eq: $slug }) {
      id
      slug
      title
      date(formatString: "MMMM DD, YYYY")
      content
      excerpt
    }
    previous: wpPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: wpPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
